import { FocusEvent, FormEvent } from "react";

export function buildOnSubmit(f: () => void) {
  return (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    f();
  };
}

let localStorageAvailable = false;
export function isLocalStorageAvailable() {
  if (localStorageAvailable) return true;
  try {
    const keyVal = "__ls_test";
    localStorage.setItem(keyVal, keyVal);
    localStorage.removeItem(keyVal);
    localStorageAvailable = true;
    return true;
  } catch (error) {
    return false;
  }
}

export const escapeHex = (value: string) => value.replace(/([^0-9A-F]+)/gi, "").substr(0, 6);

export const validHex = (value: string, opts?: { allowAlpha?: boolean; allowShort?: boolean }): boolean => {
  const hexMatcher = /^#?([0-9A-F]{3,8})$/i;

  const match = hexMatcher.exec(value);
  const length = match ? match[1].length : 0;

  return (
    ((opts?.allowShort && length === 3) || // '#rgb' format
      length === 6 || // '#rrggbb' format
      (opts?.allowAlpha && opts?.allowShort && length === 4) || // '#rgba' format
      (opts?.allowAlpha && length === 8)) ?? // '#rrggbbaa' format
    false
  );
};

export const focusInCurrentTarget = ({ relatedTarget, currentTarget }: FocusEvent) => {
  if (relatedTarget === null) return false;

  let node = relatedTarget.parentNode;

  while (node !== null) {
    if (node === currentTarget) return true;
    node = node.parentNode;
  }

  return false;
};
