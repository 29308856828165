import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from "lexical";
import { DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

import sanitise from "../../../../../../../shared/block-editor-data/sanitise-html";
import { HtmlSnippet } from "../../../../../../../shared/block-editor-data/types";

const HtmlSnippetComponent = React.lazy(
  // @ts-ignore
  () => import("./HtmlSnippetComponent"),
);

function convertHtmlSnippetElement(domNode: Node): null | DOMConversionOutput {
  const src = getNodeContent(domNode);
  if (!src) return null;
  return {
    node: $createHtmlSnippetNode({
      src: sanitise(src),
      type: "html-snippet",
      version: 1,
      indent: 0,
    }),
  };
}

/** If a Dom element looks like a Trix embed, extract the contents and use them. Otherwise, just import the node as HTML. */
function getNodeContent(node: Node) {
  if (!(node instanceof HTMLElement)) return null;
  try {
    if (node.hasAttribute("data-trix-attachment")) {
      const { content } = JSON.parse(node.getAttribute("data-trix-attachment")!);
      if (content) {
        console.log("exporting trix content", content);
        return content;
      }
    }
  } catch (e) {
    console.warn("Error parsing Trix attachment", e);
  }
  console.log("exporting node HTML", node.outerHTML);
  return node.outerHTML;
}

export type SerializedHtmlSnippetNode = Spread<HtmlSnippet, SerializedLexicalNode>;

export class HtmlSnippetNode extends DecoratorNode<JSX.Element> {
  __src: string;

  static getType(): string {
    return "html-snippet";
  }

  static clone(node: HtmlSnippetNode): HtmlSnippetNode {
    return new HtmlSnippetNode(node.__src, node.__status);
  }

  static importJSON(serializedNode: SerializedHtmlSnippetNode): HtmlSnippetNode {
    const { src } = serializedNode;

    const node = $createHtmlSnippetNode({
      src,
      type: "html-snippet",
      version: 1,
      indent: 0,
    });

    return node;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__altText);
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      iframe: () => ({
        conversion: convertHtmlSnippetElement,
        priority: 0,
      }),
      figure: () => ({
        conversion: convertHtmlSnippetElement,
        priority: 0,
      }),
    };
  }

  constructor(src: string, key?: NodeKey) {
    super(key);
    this.__src = src;
  }

  exportJSON(): SerializedHtmlSnippetNode {
    return {
      src: this.getSrc(),
      type: "html-snippet",
      version: 1,
      indent: 0,
    };
  }

  // View

  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement("div");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  updateHtmlSnippetNode(src: string, key?: string): void {
    const self = this.getWritable();
    self.__src = src;
    console.log("writing", self, src);
  }
  updateDOM(): false {
    return false;
  }

  getSrc(): string {
    return this.__src;
  }

  getAltText(): string {
    return this.__altText;
  }

  isInline() {
    return false;
  }

  decorate(_: LexicalEditor, config: EditorConfig): JSX.Element {
    return (
      <Suspense>
        <HtmlSnippetComponent src={this.__src} nodeKey={this.getKey()} theme={config.theme} />
      </Suspense>
    );
  }
}

export function $createHtmlSnippetNode({ src }: HtmlSnippet): HtmlSnippetNode {
  return new HtmlSnippetNode(src);
}

export function $isHtmlSnippetNode(node: LexicalNode | null | undefined): node is HtmlSnippetNode {
  return node instanceof HtmlSnippetNode;
}
