import React from "react";

import sanitise from "../../../../../../../shared/block-editor-data/sanitise-html";
import Alert from "../../../../../../../shared/components/design-system/Alert";
import TextArea from "../../../../../../../shared/components/design-system/TextArea";

export default function HtmlInput({
  value,
  onChange,
  label,
}: {
  value: string;
  onChange: (value: string) => void;
  label: string;
}) {
  const isSecure = sanitise(value) === value;
  return (
    <>
      {isSecure ? (
        <Alert
          variant="warning"
          message="Do not paste in content you do not trust into this box, as it could be used to embed malicious code on your reporting site"
        />
      ) : (
        <Alert
          variant="danger"
          message="The included code does not pass security checks"
          actions={[{ label: "Fix", onClick: () => onChange(sanitise(value)), variant: "tertiary" }]}
        />
      )}
      <TextArea
        className="ds-mt-5"
        value={value}
        label={label}
        onChange={onChange}
        minHeight={750}
        customValidity={isSecure ? false : "The included code does not pass security checks"}
      />
    </>
  );
}
