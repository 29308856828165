import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../shared/components/design-system/Dialog";
import { SectionFooter } from "../../../../../shared/components/design-system/component-groups/section-header-footer";
import TickShield from "../../../assets/images/EmptyStateAndUpsell/tick-shield.svg";
import { isLocalStorageAvailable } from "../../../utils";

export const REMIND_LS_KEY = "Enforce2fa-RemindLater";
const TWO_FACTOR_REMIND_LATER_DAYS = 1;

function dateDiffInDays(a: Date, b: Date) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
}

const EnforceTwoFactorAuthModal: FunctionComponent = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const remindLsTimestamp = window.localStorage.getItem(REMIND_LS_KEY);
    if (remindLsTimestamp) {
      if (dateDiffInDays(new Date(parseInt(remindLsTimestamp)), new Date()) >= TWO_FACTOR_REMIND_LATER_DAYS) {
        setModalOpen(true);
        window.localStorage.removeItem(REMIND_LS_KEY);
      }
    } else {
      setModalOpen(true);
    }
  }, []);

  const handleEnableButtonClick = useCallback(() => {
    setModalOpen(false);
    navigate("/account", { state: { openMfaModal: true } });
  }, [navigate]);

  const handleRemindMeLaterButtonClick = useCallback(() => {
    setModalOpen(false);
    if (isLocalStorageAvailable()) window.localStorage.setItem(REMIND_LS_KEY, `${Date.now()}`);
  }, []);

  return (
    <Dialog isOpen={modalOpen}>
      <div style={{ textAlign: "center" }}>
        <img src={TickShield} alt="" />
        <p className="ds-mt-5">
          In order to use the system in a safe and secure way, you must enable Two-factor Authentication.
        </p>
      </div>
      <SectionFooter>
        <Button variant="tertiary" onClick={handleRemindMeLaterButtonClick} label="Remind me later" />
        <Button onClick={handleEnableButtonClick} label="Enable 2FA" />
      </SectionFooter>
    </Dialog>
  );
};

export default EnforceTwoFactorAuthModal;
