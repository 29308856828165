import { useParams } from "react-router-dom";

import useOrgData from "../../hooks/useOrgData";
import { Breadcrumb } from "../design-system/PageHeader/CombinedHeader";
import useBaseUrl from "./useBaseUrl";

export default function useBreadcrumbs({
  siteTab,
}: {
  /** Omit this to have no site breadcrumb. Set it to the empty string to have the default one. */
  siteTab?: string;
} = {}) {
  const breadcrumbs: Breadcrumb[] = [];
  const { organisationId, siteId } = useParams<"organisationId" | "siteId">();
  const { sites, organisation } = useOrgData();
  const baseUrl = useBaseUrl();

  if (organisationId) {
    breadcrumbs.push({ url: `/organisations/${organisationId}`, label: organisation.name });
    breadcrumbs.push({ url: `/organisations/${organisationId}/sites`, label: "Sites" });
  } else if (sites.length > 1) {
    breadcrumbs.push({ url: "/sites", label: "All sites" });
  }

  if (siteTab === undefined) return breadcrumbs;

  breadcrumbs.push({
    url: `${baseUrl}/${siteTab}`,
    label: sites.find((site) => site.id === siteId)?.name ?? "Site content",
  });

  return breadcrumbs;
}
