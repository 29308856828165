import React, { useCallback } from "react";
import { createPortal } from "react-dom";

import BlockEditorContent from "../../../../../shared/block-editor-data/react";
import theme from "../../../../../shared/block-editor-data/sass-theme";
import Button from "../../../../../shared/components/design-system/Button";
import { usePersistence } from "./persistence";

export default function DownloadReportButton() {
  const { name, contents, savedDocument: doc } = usePersistence();

  const onClick = useCallback(() => {
    console.log("Download");
    document.body.classList.add("printing");
    const originalTitle = document.title;
    document.title = name;
    window.print();
    document.body.classList.remove("printing");
    document.title = originalTitle;
  }, [name]);

  if (!doc) return null;

  return (
    <>
      <Button variant="action" onClick={onClick} icon="arrowToBottom" label="Download" />
      {createPortal(
        <>
          <h1 className="ds-d1 ds-mb-7">{name}</h1>
          <BlockEditorContent content={contents} theme={theme} />
        </>,
        document.getElementById("print-container")!,
      )}
    </>
  );
}
