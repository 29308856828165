import React, { forwardRef } from "react";

import Button, { ButtonActionProps, GlobalButtonProps } from "../../../../../shared/components/design-system/Button";

export type IconButtonProps = GlobalButtonProps & ButtonActionProps & { size?: "xsmall" | "small" };

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ variant, ...props }, ref) => (
  <Button ref={ref} iconButton {...props} variant={variant ?? "tertiary"} />
));

export default IconButton;
