import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import mixpanel from "mixpanel-browser";
import React, { ChangeEvent, useCallback, useState } from "react";

import Button from "../../../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../../../shared/components/design-system/Dialog";
import TextInput from "../../../../../../../shared/components/design-system/TextInput";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../../shared/components/design-system/component-groups/section-header-footer";
import { createBlankCaption } from "../utils";
import { INSERT_TABLE_COMMAND } from "./TablePlugin";
import styles from "./styles.module.scss";

export default function InsertTableDialog({ isOpen, close }: { isOpen: boolean; close: () => void }) {
  const [rows, setRows] = useState(5);
  const [columns, setColumns] = useState(5);

  const [editor] = useLexicalComposerContext();

  const handleRowChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRows(parseInt(e.currentTarget.value, 10));
  }, []);

  const handleColumnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setColumns(parseInt(e.currentTarget.value, 10));
  }, []);

  const handleClose = useCallback(() => {
    mixpanel.track("Closed table insert modal");
    close();
  }, [close]);

  const handleSubmit = useCallback(() => {
    mixpanel.track("Table Inserted", { "Number of rows": rows, "Number of columns": columns });
    editor.dispatchCommand(INSERT_TABLE_COMMAND, {
      columns,
      rows,
      includeHeaders: true,
      caption: createBlankCaption(),
    });
    close();
  }, [close, columns, editor, rows]);

  return (
    <Dialog isOpen={isOpen} onClose={close}>
      <form onSubmit={handleSubmit}>
        <SectionHeader title="Insert table" />
        <div className="ds-hstack ds-justify-between ds-mb-5">
          <p>Number of rows</p>
          <TextInput
            className={`ds-ml-10 ${styles.InputContainer}`}
            type="number"
            max={99}
            min={1}
            value={rows}
            hideValidation
            onChange={handleRowChange}
            labelVisuallyHidden
            label="Insert rows"
          />
        </div>
        <div className="ds-hstack ds-justify-between">
          <p>Number of columns</p>
          <TextInput
            className={`ds-ml-10 ${styles.InputContainer}`}
            type="number"
            maxLength={3}
            max={99}
            min={1}
            value={columns}
            hideValidation
            onChange={handleColumnChange}
            labelVisuallyHidden
            label="Insert columns"
          />
        </div>
        <SectionFooter>
          <Button variant="tertiary" onClick={handleClose} label="Cancel" />
          <Button submit label="Insert" />
        </SectionFooter>
      </form>
    </Dialog>
  );
}
