import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { LexicalNode } from "lexical";
import React, { useCallback, useState } from "react";

import type { BlockEditorFeatureSet } from "..";
import Button from "../../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../../shared/components/design-system/Dialog";
import LinkInput from "../../../../../../shared/components/design-system/TextInput/LinkInput";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../shared/components/design-system/component-groups/section-header-footer";
import { useOpenableWithTracking } from "../../../../hooks/useBoolean";
import useOrg from "../../../../hooks/useOrg";
import IconButton from "../../Button/IconButton";
import Select from "../../Select";
import { useCommand, useSelectionProperty } from "../hooks";
import { isParentAHeadingNode } from "./utils";

export default function LinkButton({ features }: { features: BlockEditorFeatureSet }) {
  const toggleLink = useCommand(TOGGLE_LINK_COMMAND);
  const { currentSite } = useOrg();

  const { open, close, isOpen } = useOpenableWithTracking("Block Editor Link Modal");

  const [isCrossFormLink, setIsCrossFormLink] = useState(false);
  const [url, setUrl] = useState("");
  const [form, setForm] = useState("");

  const currentLinkUrl = useSelectionProperty(
    (selection) => {
      const nodes = selection?.getNodes() ?? [];
      const firstLink = nodes.find($isLinkNode);
      if (firstLink) return firstLink.getURL();
      for (let node: LexicalNode | null = nodes[0]; node; node = node.getParent()) {
        if ($isLinkNode(node)) return node.getURL();
      }
      return null;
    },
    [],
    null,
  );

  const isDisabled = useSelectionProperty(
    (selection) => {
      if (!selection) return true;
      const allNodes = selection.getNodes();
      if (selection.getTextContent().length === 0 || allNodes.some(isParentAHeadingNode)) return true;
    },
    [],
    true,
  );

  const addLink = useCallback(() => {
    toggleLink({ url: isCrossFormLink ? `/report/${form}` : url });
    close();
  }, [toggleLink, isCrossFormLink, form, url, close]);

  const removeLink = useCallback(() => {
    toggleLink(null);
    close();
  }, [toggleLink, close]);

  const handleClick = useCallback(() => {
    if (currentLinkUrl?.startsWith("/report/")) {
      setUrl("");
      setForm(currentLinkUrl.substring(8));
      setIsCrossFormLink(true);
    } else {
      setUrl(currentLinkUrl ?? "");
      setForm("");
      setIsCrossFormLink(false);
    }
    open();
  }, [currentLinkUrl, open, setUrl]);

  return (
    <>
      <Dialog isOpen={isOpen} onClose={close}>
        <SectionHeader title="Add link" />
        <form onSubmit={addLink}>
          {features.crossFormLink ? (
            <Select
              label="Link type"
              value={isCrossFormLink ? "x-form" : "url"}
              onChange={(value) => setIsCrossFormLink(value === "x-form")}
              className="ds-mb-5"
            >
              <option value="url">Link to webpage</option>
              <option value="x-form">Cross-form link</option>
            </Select>
          ) : null}
          {isCrossFormLink ? (
            <Select label="Destination form" value={form} onChange={setForm}>
              <option value="">Select a form</option>
              {currentSite?.forms.map((form) => (
                <option value={form.slug} key={form.id}>
                  {form.name}
                </option>
              ))}
            </Select>
          ) : (
            <LinkInput label="URL" value={url} onChange={setUrl} required />
          )}
          <SectionFooter>
            {currentLinkUrl ? <Button variant="secondary" onClick={removeLink} label="Remove link" /> : null}
            <Button variant="tertiary" onClick={close} className="ds-ml-auto" label="Cancel" />
            <Button submit label={currentLinkUrl ? "Update link" : "Add link"} />
          </SectionFooter>
        </form>
      </Dialog>
      <IconButton
        size="small"
        variant="tertiary"
        onClick={handleClick}
        isActive={!!currentLinkUrl}
        disabled={isDisabled}
        label="Add link"
        icon="link"
      />
    </>
  );
}
