import useOrg from "../../hooks/useOrg";

export default function useBaseUrl() {
  const {
    currentOrg: { organisation, isCrossOrg },
    currentSite,
  } = useOrg();

  if (isCrossOrg && !currentSite) {
    return `/organisations/${organisation.id}`;
  } else if (isCrossOrg && currentSite) {
    return `/organisations/${organisation.id}/sites/${currentSite!.id}`;
  } else if (currentSite) {
    return `/sites/${currentSite.id}`;
  } else {
    return;
  }
}
