import format from "date-fns/format";
import sub from "date-fns/sub";
import { isEqual } from "lodash";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo, useState } from "react";

import { FormCategory } from "../../../../../../../../__generated__/graphql";
import { OverTimeChartBlockOptions } from "../../../../../../../../shared/block-editor-data/chart-block-types";
import Alert from "../../../../../../../../shared/components/design-system/Alert";
import Button from "../../../../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../../../../shared/components/design-system/Dialog";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../../../shared/components/design-system/component-groups/section-header-footer";
import { useConfirm } from "../../../../../../hooks/useAlert";
import useReportFields, { Column } from "../../../../../../hooks/useReportFields";
import { ALLOWED_QUESTION_TYPES, DEFAULT_ANALYTICS_CODES } from "../../../../../analytics/AnalyticsOverTimePage";
import ChartActions from "../../../../../analytics/AnalyticsOverTimePage/ChartActions";
import ChartPanel from "../../../../../analytics/AnalyticsOverTimePage/ChartPanel";
import TimeframeSelection from "../../../../../analytics/AnalyticsOverTimePage/TimeframeSelection";
import overTimeStyles from "../../../../../analytics/AnalyticsOverTimePage/index.module.scss";
import useOverTimeQuery from "../../../../../analytics/AnalyticsOverTimePage/useOverTimeQuery";
import { OverTimeYearSettings } from "../../../../../analytics/AnalyticsOverTimePage/useUrlYearSettings";
import { ContainerSize } from "../../../../Container";
import FilterChips from "../../../../FilterChips";
import FilterPanel from "../../../../FilterPanel";
import PermissionCheck from "../PermissionCheck";
import useCancelWithConfirm from "../useCancelWithConfirm";
import useChartTypeSettings from "../useChartTypeSettings";
import { useFilterSettings } from "../useFilterSettings";
import UnderlyingDataEditor from "./OverTimeDataEditor";

const DUMMY_VALUE: OverTimeChartBlockOptions = {
  data: { years: [] },
  chartType: "BAR",
  filter: { excludeSpam: true },
  year: format(sub(new Date(), { years: 1 }), "yyyy"),
  month: format(new Date(), "MM"),
  comparisonYear: null,
  duration: "12",
  dataHasBeenEdited: false,
};

export default function EditOverTimeChartDialog({
  onOk,
  onCancel,
  defaultValue: defaultValueProp,
}: {
  onOk: (settings: OverTimeChartBlockOptions) => void;
  onCancel: () => void;
  defaultValue?: OverTimeChartBlockOptions;
}) {
  const insertMode = !defaultValueProp;
  const defaultValue = defaultValueProp ?? DUMMY_VALUE;

  const confirm = useConfirm();

  const filterSettings = useFilterSettings(defaultValue.filter);

  const [year, setYear] = useState(defaultValue.year);
  const [comparisonYear, setComparisonYear] = useState(defaultValue.comparisonYear);
  const [month, setMonth] = useState<string>(defaultValue.month);
  const [duration, setDuration] = useState<string>(defaultValue.duration);
  const yearSettings = useMemo<OverTimeYearSettings>(
    () => ({ year, setYear, comparisonYear, setComparisonYear, month, setMonth, duration, setDuration }),
    [comparisonYear, month, year, duration],
  );
  const chartTypeSettings = useChartTypeSettings(defaultValue.chartType);

  const [editedData, setEditedData] = useState(defaultValue.dataHasBeenEdited ? defaultValue.data : null);

  const { data, error, loading } = useOverTimeQuery(yearSettings, filterSettings);

  const allColumns = useReportFields(ALLOWED_QUESTION_TYPES);
  const defaultPanels: Column[] = allColumns.filter((col) => DEFAULT_ANALYTICS_CODES.includes(col.analyticsCode));

  const onCancelWithConfirm = useCancelWithConfirm(
    onCancel,
    [year, month, comparisonYear, chartTypeSettings.chartType, filterSettings.filter],
    [defaultValue.year, defaultValue.month, defaultValue.comparisonYear, defaultValue.chartType, defaultValue.filter],
  );

  const save = useCallback(() => {
    if (data) {
      mixpanel.track("Over time analytics inserted", {
        chartType: chartTypeSettings.chartType,
        year: year,
        month: month,
        comparisonYear: comparisonYear,
        duration: duration,
        filter: filterSettings.filter.questions?.length ? true : false,
      });
      onOk({
        chartType: chartTypeSettings.chartType,
        filter: filterSettings.filter,
        data: editedData ?? data,
        year,
        month,
        comparisonYear,
        duration,
        dataHasBeenEdited: !!editedData,
      });
    }
  }, [
    chartTypeSettings.chartType,
    comparisonYear,
    data,
    duration,
    editedData,
    filterSettings.filter,
    month,
    onOk,
    year,
  ]);

  const openDataEditor = useCallback(() => {
    setEditedData(data);
    mixpanel.track("Opened 'edit underlying data' page", { type: "over-time" });
  }, [data]);

  const discardEditedData = useCallback(async () => {
    if (
      isEqual(data, editedData) ||
      (await confirm({
        title: "Discard edits to data?",
        children: "Returning to the chart builder will delete any changes you have made to the data.",
        okCaption: "Continue",
        okVariant: "danger",
        cancelCaption: "Cancel",
      }))
    ) {
      setEditedData(null);
      mixpanel.track("Closed 'edit underlying data' page", { type: "over-time" });
    }
  }, [confirm, data, editedData]);

  const dataHasChanged = useMemo(() => {
    if (insertMode || !data || defaultValue.dataHasBeenEdited) return false;
    if (isEqual(data, defaultValue.data)) return false;
    if (year !== defaultValue.year) return false;
    if (month !== defaultValue.month) return false;
    if (comparisonYear !== defaultValue.comparisonYear) return false;
    if (!isEqual(filterSettings.filter, defaultValue.filter)) return false;
    return true;
  }, [comparisonYear, insertMode, data, defaultValue, filterSettings.filter, month, year]);

  return (
    <PermissionCheck close={onCancel}>
      <Dialog size={ContainerSize.Large} fullHeight isOpen onClose={onCancel} onClickClose={onCancelWithConfirm}>
        <SectionHeader title={insertMode ? "Insert analytics data" : "Edit analytics data"} />
        {editedData ? (
          <UnderlyingDataEditor
            value={editedData}
            onChange={setEditedData}
            year={year}
            month={month}
            comparisonYear={comparisonYear}
          />
        ) : (
          <>
            {dataHasChanged ? (
              <Alert
                variant="info"
                title="The live data has changed since you generated this chart."
                message="Clicking 'update' will update the chart in your report to reflect this."
              />
            ) : null}
            <div className={`${overTimeStyles.OverTimeHeader} ds-my-7`}>
              <TimeframeSelection settings={yearSettings} />
              <FilterPanel
                settings={filterSettings}
                defaultPanels={defaultPanels}
                className={overTimeStyles.OverTimeHeader__filterButton}
                category={FormCategory.Reporting}
              />
              <FilterChips className={overTimeStyles.OverTimeHeader__filterChips} settings={filterSettings} />
            </div>
            <ChartActions
              loading={loading}
              yearSettings={yearSettings}
              data={data}
              chartTypeSettings={chartTypeSettings}
              container={null}
            />
            <ChartPanel
              loading={loading}
              error={error}
              data={data}
              yearSettings={yearSettings}
              filterSettings={filterSettings}
              chartTypeSettings={chartTypeSettings}
            />
          </>
        )}
        <SectionFooter>
          {editedData ? (
            <Button
              onClick={discardEditedData}
              variant="secondary"
              label={insertMode ? "Back to chart builder" : "Go to chart builder"}
            />
          ) : null}
          <Button onClick={onCancelWithConfirm} variant="tertiary" className="ds-ml-auto" label="Cancel" />
          {!editedData ? (
            <Button onClick={openDataEditor} variant="secondary" disabled={loading || !!error} label="Edit data" />
          ) : null}
          <Button onClick={save} disabled={loading || !!error} label={insertMode ? "Insert" : "Update"} />
        </SectionFooter>
      </Dialog>
    </PermissionCheck>
  );
}
