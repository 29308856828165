import { LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableRowNode } from "@lexical/table";
import { Klass, LexicalEditor, LexicalNode } from "lexical";
import React, { useCallback, useMemo, useState } from "react";

import { RichTextDocument } from "../../../../../shared/block-editor-data/types";
import Alert from "../../../../../shared/components/design-system/Alert";
import { ContentEditableLabel } from "../../../../../shared/components/design-system/InputLabel";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import useDeepEquality from "../../../../../shared/hooks/useDeepEquality";
import useGeneratedId from "../../../../../shared/hooks/useGeneratedId";
import AutoFocusPlugin from "./AutoFocusPlugin/AutoFocusPlugin";
import DefaultTabbedPlugin from "./DefaultTabbedPlugin/DefaultTabbedPlugin";
import ImportHtmlPlugin, { initialContent } from "./ImportHtmlPlugin";
import NormalisationPlugin from "./NormalisationPlugin";
import Toolbar from "./Toolbar";
import { FileUploadNode } from "./blocks/FileUploadBlock/FileUploadNode";
import FileUploadPlugin from "./blocks/FileUploadBlock/FileUploadPlugin";
import { HtmlSnippetNode } from "./blocks/HtmlSnippetBlock/HtmlSnippetNode";
import HtmlSnippetPlugin from "./blocks/HtmlSnippetBlock/HtmlSnippetPlugin";
import { ImageNode } from "./blocks/ImageBlock/ImageNode";
import ImagePlugin from "./blocks/ImageBlock/ImagePlugin";
import { UploadProvider } from "./blocks/ImageBlock/useUpload";
import { NestedEditorContext } from "./blocks/NestedContext";
import inDetailChartPlugin from "./blocks/chart/in-detail";
import overTimeChartPlugin from "./blocks/chart/over-time";
import { TableNode } from "./blocks/table/TableNode";
import { TablePlugin } from "./blocks/table/TablePlugin";
import styles from "./styles.module.scss";
import theme from "./text-styling/theme";

export interface BlockEditorFeatureSet {
  /** Whether to allow Insights-reports-style charts */
  analyticsCharts: boolean;
  /** Hides the undo, redo and underline buttons. These features can still be used by (eg) keyboard shortcuts, but the buttons are hidden to save space */
  compactToolbar: boolean;
  showToolbar: boolean;
  headings: boolean;
  /** The "switch from anonymous to named" link */
  crossFormLink: boolean;
  tables: boolean;
  images: boolean;
  files: boolean;
  htmlSnippets: boolean;
  // Lists, links, undo, redo, and basic formatting are all enabled by default and cannot currently be disabled. This wouldn't be hard to add but it's not currently supported.
}

export default function BlockEditor({
  defaultValue,
  onChange,
  autoFocus,
  main,
  className,
  editorClassName,
  features: featuresParam,
  label,
  visuallyHideLabel,
}: {
  /** Either a RichTextDocument made in Lexical, null for the default empty document, or an HTML snippet as a string to be converted */
  defaultValue?: RichTextDocument | string | null;
  onChange: (value: RichTextDocument) => void;
  autoFocus?: boolean;
  /** true if this is the most important element on the page, eg, a document editor screen. false if it's one of several */
  main?: boolean;
  className?: string;
  editorClassName?: string;
  features: BlockEditorFeatureSet;
  label?: string;
  visuallyHideLabel?: boolean;
}) {
  const toast = useToast();
  const id = useGeneratedId();
  const features = useDeepEquality(featuresParam);

  const [inlineError, setInlineError] = useState<string | null>(null);
  const toastError = useCallback(
    (e: Error, editor: LexicalEditor) => {
      const { error, showInline } = getMessage(e);
      if (showInline) setInlineError(error);
      else toast.error(error, { title: "Error editing text" });
    },
    [toast],
  );

  const config = useMemo(() => {
    const nodes: Klass<LexicalNode>[] = [ListNode, ListItemNode, LinkNode];

    if (features.headings) nodes.push(HeadingNode);
    if (features.analyticsCharts) nodes.push(inDetailChartPlugin.node, overTimeChartPlugin.node);
    if (features.tables) nodes.push(TableCellNode, TableRowNode, TableNode);
    if (features.images) nodes.push(ImageNode);
    if (features.files) nodes.push(FileUploadNode);
    if (features.htmlSnippets) nodes.push(HtmlSnippetNode);

    return {
      namespace: "CSBlockEditor",
      theme,
      onError: toastError,
      editorState: initialContent(defaultValue),
      nodes,
    };
  }, [defaultValue, toastError, features]);

  const nestedEditorConfig = useMemo(
    () => ({
      namespace: "nestedEditor",
      nodes: [LinkNode],
      onError: toastError,
      theme,
    }),
    [toastError],
  );

  const labelId = `${id}-label`;

  // The LexicalComposer component doesn't accept null as a child, so our ternaries fall back to empty strings
  return (
    <div className={`${main ? styles.MainElement : ""} ${className ?? ""}`}>
      {label ? (
        <ContentEditableLabel id={labelId} htmlFor={id} className={visuallyHideLabel ? "is-sr-only" : ""}>
          {label}
        </ContentEditableLabel>
      ) : null}
      {inlineError ? <Alert variant="danger" message={inlineError} onClickClose={() => setInlineError(null)} /> : null}
      <LexicalComposer initialConfig={config}>
        <NestedEditorContext>
          <UploadProvider>
            <Toolbar features={features} />
            {typeof defaultValue === "string" ? <ImportHtmlPlugin html={defaultValue} /> : null}
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  id={id}
                  ariaLabelledBy={label ? labelId : undefined}
                  className={`${styles.ContentEditable} ${editorClassName ?? ""}`}
                />
              }
              placeholder=""
            />
            {features.images ? (
              <ImagePlugin nestedEditorConfig={nestedEditorConfig}>
                <AutoFocusPlugin />
                <RichTextPlugin
                  contentEditable={
                    <ContentEditable className={`${styles.NestedEditor__contentEditable} ${editorClassName ?? ""}`} />
                  }
                  placeholder=""
                />
              </ImagePlugin>
            ) : (
              ""
            )}
            {features.files ? <FileUploadPlugin /> : ""}
            {features.htmlSnippets ? <HtmlSnippetPlugin /> : ""}
            <ListPlugin />
            <LinkPlugin />
            {features.analyticsCharts ? <inDetailChartPlugin.Plugin /> : ""}
            {features.analyticsCharts ? <overTimeChartPlugin.Plugin /> : ""}
            <NormalisationPlugin onChange={onChange} />
            <HistoryPlugin />
            {autoFocus ? <AutoFocusPlugin /> : ""}
            {features.tables ? (
              <TablePlugin nestedEditorConfig={nestedEditorConfig}>
                <AutoFocusPlugin />
                <RichTextPlugin
                  contentEditable={
                    <ContentEditable className={`${styles.NestedEditor__contentEditable} ${editorClassName ?? ""}`} />
                  }
                  placeholder=""
                />
              </TablePlugin>
            ) : (
              ""
            )}
            <DefaultTabbedPlugin />
          </UploadProvider>
        </NestedEditorContext>
      </LexicalComposer>
    </div>
  );
}

function getMessage(error: Error): { error: string; showInline: true } | { error: Error | string; showInline: false } {
  if (!error) return { error: "An error occurred", showInline: false };
  const { message } = error;

  const nodeNotFound = message.match(/^parseEditorState: type "(.*)" \+ not found$/);
  if (nodeNotFound?.[0]) {
    return {
      error: `This editor could not be shown, as it contained content that is not allowed here (${nodeNotFound[1]}). Please re-enter the text before saving.`,
      showInline: true,
    };
  }

  console.warn("Lexical error", { message, error });
  return { error, showInline: false };
}
