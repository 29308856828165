import { $isListNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, $isHeadingNode, HeadingNode } from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import { $createParagraphNode, $getSelection, $isParagraphNode, $isRangeSelection } from "lexical";
import React, { useCallback } from "react";

import type { BlockEditorFeatureSet } from "../..";
import { Translation } from "../../../../../../../shared/components/translation";
import { importTranslationsBackground } from "../../../../../../../shared/translation/initialisation";
import Select from "../../../Select";
import { useIsRootEditor, useSelectionProperty } from "../../hooks";
import english from "./translations/en-GB.json";

function valueIfAllSame<T>(list: T[]): T | false {
  const [first, ...others] = list;
  if (others.some((other) => other !== first)) return false;
  return first;
}

importTranslationsBackground("StylingDropdown", { "en-GB": english });

export default function StylingDropdown({ features }: { features: BlockEditorFeatureSet }) {
  const currentNodeType = useSelectionProperty(
    (selection) => {
      if (!selection) return "";
      const nodes = selection.getNodes().map((node) => node.getTopLevelElementOrThrow());
      if (nodes.every((node) => $isParagraphNode(node))) return "p";
      if (nodes.every((node) => $isListNode(node))) {
        const type = valueIfAllSame((nodes as ListNode[]).map((node) => node.getListType()));
        if (type === "bullet") return "ul";
        if (type === "number") return "ol";
        return "";
      }
      if (nodes.every((node) => $isHeadingNode(node))) {
        return valueIfAllSame((nodes as HeadingNode[]).map((node) => node.getTag())) || "";
      }
      if (nodes.every((node) => $isParagraphNode(node) || $isListNode(node) || $isHeadingNode(node))) {
        return "";
      }
      return "invalid";
    },
    [],
    "",
  );

  const [editor] = useLexicalComposerContext();
  const changeNodeType = useCallback(
    (type: string) => {
      switch (type) {
        case "p":
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              $wrapNodes(selection, () => $createParagraphNode());
            }
          });
          break;
        case "ul":
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          break;
        case "ol":
          editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
          break;
        case "h2":
        case "h3":
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              $wrapNodes(selection, () => $createHeadingNode(type));
            }
          });
          break;
      }
    },
    [editor],
  );

  const disableSelect = currentNodeType === "invalid";
  const selectValue = disableSelect ? "" : currentNodeType;

  return (
    <div>
      <Select
        value={selectValue}
        size="small"
        variant="ghost"
        onChange={changeNodeType}
        label="Formatting options"
        hideLabel
        disabled={!useIsRootEditor() || disableSelect}
      >
        {selectValue === "" ? <option value=""></option> : null}
        <option value="p">
          <Translation t="StylingDropdown:p" />
        </option>
        <option value="ul">
          <Translation t="StylingDropdown:ul" />
        </option>
        <option value="ol">
          <Translation t="StylingDropdown:ol" />
        </option>
        {features.headings ? (
          <>
            <option value="h2">
              <Translation t="StylingDropdown:h2" />
            </option>
            <option value="h3">
              <Translation t="StylingDropdown:h3" />
            </option>
          </>
        ) : null}
      </Select>
    </div>
  );
}
