import mixpanel from "mixpanel-browser";
import React, { FunctionComponent, ReactNode, useCallback, useState } from "react";

import Dialog from "../../../../../../shared/components/design-system/Dialog";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../shared/components/design-system/component-groups/section-header-footer";
import Button, { ButtonWrapperProps } from "../index";

interface ConfirmDeleteButtonProps extends Omit<ButtonWrapperProps, "onClick"> {
  title: string;
  confirmBody: string | ReactNode;
  confirmText: string;
  track?: string;
  onConfirm: () => void;
}

const ConfirmDeleteButton: FunctionComponent<ConfirmDeleteButtonProps> = ({
  title,
  confirmBody,
  confirmText,
  track,
  onConfirm,
  children,
  ...buttonProps
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openConfirmModal = useCallback(() => {
    if (track) {
      mixpanel.track(`Opened the ${track} delete modal`);
    }
    setModalOpen(true);
  }, [track]);

  const closeConfirmModal = useCallback(() => {
    if (track) {
      mixpanel.track(`Closed the ${track} delete modal`);
    }
    setModalOpen(false);
  }, [track]);

  const handleConfirm = useCallback(() => {
    if (track) {
      mixpanel.track(`${track} delete modal confirm button clicked`);
    }
    setModalOpen(false);
    onConfirm();
  }, [onConfirm, track]);

  return (
    <>
      <Dialog isOpen={modalOpen} onClose={closeConfirmModal}>
        <SectionHeader title={title} />
        {typeof confirmBody === "string" ? <p>{confirmBody}</p> : confirmBody}
        <SectionFooter>
          <Button type="button" variant="tertiary" onClick={closeConfirmModal}>
            Cancel
          </Button>
          <Button type="button" variant="danger" onClick={handleConfirm}>
            {confirmText}
          </Button>
        </SectionFooter>
      </Dialog>
      <Button {...buttonProps} type="button" onClick={openConfirmModal}>
        {children}
      </Button>
    </>
  );
};

export default ConfirmDeleteButton;
