import mixpanel from "mixpanel-browser";
import React, { MouseEvent, useCallback } from "react";
import { useFlag } from "react-unleash-flags";

import {
  AccessTypeWithCustom,
  AnswerAccessType,
  BinaryAccessType,
  ReportAccessType,
} from "../../../../../__generated__/graphql";
import Icon from "../../../../../shared/components/design-system/Icon";
import analyticsUpsellGraphic from "../../../assets/images/analytics-upsell.png";
import useBoolean from "../../../hooks/useBoolean";
import useOrg from "../../../hooks/useOrg";
import UpsellModal from "../../design-system/Dialog/UpsellModal";
import Logo from "../../design-system/Logo";
import NavigationAccordion, { NavigationSubMenuItem } from "./NavigationAccordion";
import NavigationItem from "./NavigationItem";
import "./styles.scss";

export default function Navigation({
  isCollapsed,
  onToggleCollapse,
  onClickLogOut,
  onToggleAccordion,
  openAccordions,
}: {
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  onClickLogOut: (event: MouseEvent) => void;
  onToggleAccordion: (title: string) => void;
  openAccordions: string[];
}) {
  const { loggedInUser, isSuperAdmin } = useOrg();
  const self = { ...loggedInUser, isSuperAdmin };
  const analyticsAccess = self.hasAnalyticsAccess && self.org.featureGates.includes("reportAnalytics");
  const surveyAccess = self.hasSurveyAccess && self.org.featureGates.includes("surveys");
  const caseManagementFeatureFlag = useFlag("case-management-forms");

  const { value: isOpenMobile, toggle: handleMobileToggleClick, setFalse: closeMobileNavigation } = useBoolean();
  const { value: analyticsUpsellOpen, setTrue: openUpsellModal, setFalse: closeUpsellModal } = useBoolean();

  const analyticsOnClick = useCallback(
    (e: MouseEvent) => {
      if (!analyticsAccess) {
        openUpsellModal();
        e.preventDefault();
        mixpanel.track("Report analytics upsell opened");
      }
    },
    [openUpsellModal, analyticsAccess],
  );

  return (
    <>
      <aside className={`c-navigation ${isCollapsed ? "is-collapsed" : ""} ${isOpenMobile ? "is-open-mobile" : ""}`}>
        {isOpenMobile ? <div className="c-navigation__overlay" onClick={handleMobileToggleClick} /> : null}

        <button className="c-navigation__mobile-toggle" onClick={handleMobileToggleClick}>
          <span className="is-sr-only">{isOpenMobile ? "Close" : "Open"} navigation menu</span>
          <Icon icon="navicon" className="c-navigation__menu-icon" aria-hidden="true" />
          <Icon icon="xmarkLarge" className="c-navigation__close-icon" aria-hidden="true" />
        </button>

        <button className="c-navigation__collapse-toggle" onClick={onToggleCollapse}>
          <span className="is-sr-only">Toggle collapsed navigation</span>
          <Icon icon="chevronDown" />
        </button>

        <header className="c-navigation__header">
          <a className="c-navigation__logo-wrapper" href="/">
            <Logo className="c-navigation__logo-svg c-navigation__logo-svg--large" variant="light" />
            <Logo className="c-navigation__logo-svg c-navigation__logo-svg--small" variant="light" small />
          </a>
        </header>

        {self.org.isSuspended ? null : (
          <nav className="c-navigation__menu">
            <ul className="c-navigation__list">
              {self.hasReportsAccess ? (
                <NavigationItem
                  title="Reports"
                  icon="ballot"
                  to="/reports"
                  closeMobileNavigation={closeMobileNavigation}
                  isNavCollapsed={isCollapsed}
                />
              ) : null}
              {analyticsAccess ? (
                <NavigationItem
                  title="Analytics"
                  icon="chart"
                  to="/analytics"
                  onClick={analyticsOnClick}
                  closeMobileNavigation={closeMobileNavigation}
                  isNavCollapsed={isCollapsed}
                />
              ) : null}
              {surveyAccess ? (
                <NavigationItem
                  title="Surveys"
                  icon="check"
                  to="/surveys"
                  closeMobileNavigation={closeMobileNavigation}
                  isNavCollapsed={isCollapsed}
                />
              ) : null}
              {self.hasSiteAccess || self.hasFormsAccess ? (
                <NavigationItem
                  title="Content"
                  icon="image"
                  to="/sites"
                  closeMobileNavigation={closeMobileNavigation}
                  isNavCollapsed={isCollapsed}
                />
              ) : null}
              {self.hasUsersAccess ? (
                <NavigationItem
                  title="Users"
                  icon="user"
                  to="/users/users"
                  subPages={["/users"]}
                  closeMobileNavigation={closeMobileNavigation}
                  isNavCollapsed={isCollapsed}
                />
              ) : null}
              <NavigationItem
                title="Resources"
                icon="folderDownload"
                to="/resources"
                closeMobileNavigation={closeMobileNavigation}
                isNavCollapsed={isCollapsed}
              />

              {self.team.teamAccess === AccessTypeWithCustom.All ? (
                <NavigationAccordion
                  title="Admin"
                  icon="sliders"
                  isNavCollapsed={isCollapsed}
                  subPages={["/admin"]}
                  toggleAccordion={onToggleAccordion}
                  openAccordions={openAccordions}
                >
                  {self.team.reportAccess === ReportAccessType.All &&
                  self.org.featureGates.includes("caseManagementForms") &&
                  caseManagementFeatureFlag ? (
                    <NavigationSubMenuItem
                      title="Forms"
                      to="/admin/forms"
                      closeMobileNavigation={closeMobileNavigation}
                      isNavCollapsed={isCollapsed}
                    />
                  ) : null}
                  {self.team.reportAccess === ReportAccessType.All ? (
                    <NavigationSubMenuItem
                      title="Outcomes"
                      to="/admin/outcomes"
                      closeMobileNavigation={closeMobileNavigation}
                      isNavCollapsed={isCollapsed}
                    />
                  ) : null}
                  {self.team.reportAccess === ReportAccessType.All ? (
                    <NavigationSubMenuItem
                      title="Options"
                      to="/admin/options"
                      closeMobileNavigation={closeMobileNavigation}
                      isNavCollapsed={isCollapsed}
                    />
                  ) : null}
                  {self.team.teamAccess === AccessTypeWithCustom.All ? (
                    <NavigationSubMenuItem
                      title="Audit log"
                      to="/admin/audit"
                      closeMobileNavigation={closeMobileNavigation}
                      isNavCollapsed={isCollapsed}
                    />
                  ) : null}
                  {self.team.answerAccess === AnswerAccessType.All &&
                  self.team.reportAccess === ReportAccessType.All &&
                  self.org?.featureGates?.includes("multipleMentions") ? (
                    <NavigationSubMenuItem
                      title="Name matching"
                      to="/admin/name-matching"
                      closeMobileNavigation={closeMobileNavigation}
                      isNavCollapsed={isCollapsed}
                    />
                  ) : null}
                  {self.team.teamAccess === AccessTypeWithCustom.All &&
                  self.team.siteAccess === BinaryAccessType.All &&
                  self.team.reportAccess === ReportAccessType.All &&
                  self.team.formAccess === BinaryAccessType.All &&
                  self.team.answerAccess === AnswerAccessType.All ? (
                    <NavigationSubMenuItem
                      title="Data export"
                      to="/admin/export"
                      closeMobileNavigation={closeMobileNavigation}
                      isNavCollapsed={isCollapsed}
                    />
                  ) : null}
                </NavigationAccordion>
              ) : null}
              <NavigationItem
                title="Help"
                icon="circleInfo"
                to="/help"
                closeMobileNavigation={closeMobileNavigation}
                isNavCollapsed={isCollapsed}
              />
              {self.isSuperAdmin ? (
                <NavigationItem
                  title="Partners"
                  icon="organisations"
                  to="/organisations"
                  closeMobileNavigation={closeMobileNavigation}
                  isNavCollapsed={isCollapsed}
                />
              ) : null}
            </ul>
          </nav>
        )}
        <footer className="c-navigation__footer">
          <ul className="c-navigation__list">
            {self.name && !self.org.isSuspended ? (
              <NavigationItem
                title={self.name}
                to="/account"
                neverActive
                icon="userCircle"
                closeMobileNavigation={closeMobileNavigation}
                isNavCollapsed={isCollapsed}
              />
            ) : null}
            <NavigationItem
              title="Log out"
              neverActive
              to="/"
              onClick={onClickLogOut}
              icon="signOut"
              closeMobileNavigation={closeMobileNavigation}
              isNavCollapsed={isCollapsed}
            />
          </ul>
        </footer>
      </aside>
      <UpsellModal
        isOpen={analyticsUpsellOpen}
        onClose={closeUpsellModal}
        graphic={analyticsUpsellGraphic}
        title="Report Analytics"
      >
        <p>
          Report Analytics are part of our <strong>Silver</strong> and <strong>Gold</strong> packages and enable you to
          gain actionable insights, learn more about your culture and identify patterns of behaviour through real-time
          reporting.
        </p>
        <p>If you’d like to find out more about upgrading, get in touch with our Partnerships team.</p>
      </UpsellModal>
    </>
  );
}
