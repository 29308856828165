import mixpanel from "mixpanel-browser";
import { useEffect, useMemo } from "react";

import {
  AnalyticsSplit,
  AnalyticsQuery as RawAnalyticsQueryResult,
  ReportSearch,
} from "../../../../../__generated__/graphql";
import { ChartType, InDetailChartOptions, InDetailData } from "../../../../../shared/components/charts/types";
import { mixpanelFilterSummary } from "../../../hooks/filtering/helpers";
import { FilterSettings } from "../../../hooks/filtering/useFilterFromUrlParams";
import useOrgData from "../../../hooks/useOrgData";
import { TimePeriod } from "../../../timePeriods";
import { LoadingData } from "../AnalyticsOverTimePage/useOverTimeQuery";
import splitOn from "../split";
import useAnalyticsQuery, {
  AnalyticsQueryProps,
  AnalyticsQueryResult,
  UseAnalyticsQueryResult,
} from "../useAnalyticsQuery";
import { categoryMatchesFilter } from "./AnalysisChartPanel/series";
import { SplitSettings } from "./useUrlSplitDimension";

// rawDataMapping is separate from 'data' because it's not stored in Insights reports — it's just held in memory in the 'live' analytics system and used to generate links
export interface RawDataMapping {
  categories: AnalyticsQueryResult[];
  segments: (AnalyticsQueryResult | undefined)[][] | null;
  splits: AnalyticsSplit[];
}
type InDetailResult = LoadingData<InDetailData> & {
  rawDataMapping: RawDataMapping | null;
};

export default function useInDetailQuery(
  firstSplit: SplitSettings,
  secondSplit: SplitSettings,
  // The period is passed through solely for Mixpanel's benefit — the actual filtering is done via the FilterSettings object which we assume will match:
  timePeriod: TimePeriod,
  chartOptions: InDetailChartOptions,
  chartType: ChartType,
  { filter, defaults }: FilterSettings,
  skip?: boolean,
  language?: string | null,
): InDetailResult {
  const orgData = useOrgData();

  // Converts your splits from SplitSettings, which is used to manage which splits you want, to AnalyticsSplit, which is the format the analytics API itself wants.
  const splits = useMemo(() => {
    const splits = [splitOn(firstSplit, orgData)];
    if (secondSplit.analyticsCode || secondSplit.questionId) splits.push(splitOn(secondSplit, orgData));
    return splits;
  }, [orgData, firstSplit, secondSplit]);

  // Build the actual queries we're going to send to the analytics API.
  const queries: AnalyticsQueryProps[] = [
    {
      organisationId: orgData.organisation.id,
      splits,
      search: filter,
      language,
    },
  ];

  // If we need a "grandTotal" value, that will need a separate query.
  if (chartOptions.showPercentages) {
    queries.push({
      organisationId: orgData.organisation.id,
      search: filter,
      language,
    });
  }

  // Whenever any of these values change we're going to re-run the query
  useEffect(() => {
    mixpanel.track("Made 'in detail' query", {
      analyticsCode: firstSplit.analyticsCode,
      segmentBy: secondSplit.analyticsCode,
      mainGroups: firstSplit.groups.basis,
      splitGroups: secondSplit.groups.basis,
      chartType,
      period: timePeriod,
      ...mixpanelFilterSummary(filter, defaults, orgData.questions),
      ...chartOptions,
    });
  }, [
    chartType,
    filter,
    defaults,
    orgData.questions,
    chartOptions,
    firstSplit.analyticsCode,
    firstSplit.groups.basis,
    secondSplit.analyticsCode,
    secondSplit.groups.basis,
    timePeriod,
  ]);

  // This runs the query whenever "queries" changes. It uses a deep-equals comparison so there's no need to useMemo the "queries" value.
  const result = useAnalyticsQuery(queries, skip);

  // When the data comes back, process the raw numbers into something the UI understands.
  return useMemo(
    () => processRawResult(result, splits, filter, chartOptions, firstSplit, secondSplit),
    [result, splits, filter, chartOptions, firstSplit, secondSplit],
  );
}

export function processRawResult(
  { error, loading, data }: UseAnalyticsQueryResult,
  splits: AnalyticsSplit[],
  filter: ReportSearch,
  chartOptions: InDetailChartOptions,
  firstSplit: SplitSettings,
  secondSplit: SplitSettings,
): InDetailResult {
  // First, handle the cases where we don't have the data yet — which either means we're still loading it, or that something's gone wrong.
  if (!data) {
    if (loading) {
      return { loading, data: null, rawDataMapping: null, error };
    }
    return {
      loading: false,
      data: null,
      rawDataMapping: null,
      error: error ?? new Error("Unknown error"),
    };
  }

  // The charts and tables themselves don't really care where a value came from as long as it has a number and a label, but if we're generating links to the reports page (which currently means "in analytics, not in Insights reports") we do need information about what filter was applied to generate each category/segment, so that's stored here.
  const rawDataMapping: RawDataMapping = { categories: [], segments: null, splits };

  // This is where we'll build the "processed" data, ie, the data in the format the UI needs to render the chart/table:
  const processedData: InDetailData = {
    grandTotal: data[1]?.data[0].value,
    categories: [],
  };

  // Bail out now if there's no data
  if (data[0].data.length === 0) {
    return { loading, error, rawDataMapping, data: processedData };
  }

  // Build the main outer "categories" list
  for (const dataCategory of data[0].data) {
    const firstSplitWithData = data[0].query.splits?.[0];
    // Hide any categories that we'd be filtering out the majority of. (If you set a filter for "bullying" and "harassment" only, and one reporter checked "bullying" and "assault", you'd get a tiny bar for "assault" which is usually not what you want, so we just hide it.)
    if (filter && !categoryMatchesFilter(dataCategory, filter, firstSplitWithData!, chartOptions)) continue;
    processedData.categories.push({
      label: getLabel(dataCategory, firstSplit),
      count: dataCategory.value,
    });
    rawDataMapping.categories.push(dataCategory);
  }

  // Sort the data into alphabetical order — this is not a great order for the data to be in, but it's a reasonable default from which the user can create a better order (if we've built that by the time you read this)
  alphabetise(processedData.categories, rawDataMapping.categories);

  // In theory we should know whether the data has segments by whether we asked for it to, but the state can be slightly out of sync — checking like this means the failure mode is a flash of bad content rather than a crash
  // if however we have filtered out all the categories then there will be an empty array and we need to bail out, hence the '?'
  if (!rawDataMapping.categories[0]?.segments) {
    return { loading, error, rawDataMapping, data: processedData };
  }

  processedData.splits = [];
  const secondSplitWithData = data[0].query.splits?.[1];
  rawDataMapping.segments = rawDataMapping.categories.map(() => []);

  // First pass to pull out the list of all splits, so that every category matches
  const splitIds: string[] = [];
  for (const dataCategory of rawDataMapping.categories) {
    for (const split of dataCategory.segments!) {
      if (filter && !categoryMatchesFilter(split, filter, secondSplitWithData!, chartOptions)) continue;
      const id = segmentId(split);
      if (splitIds.includes(id)) continue;
      splitIds.push(id);
      processedData.splits.push({ label: getLabel(split, secondSplit) });
    }
  }
  processedData.categories.forEach((category, i) => {
    const dataCategory = rawDataMapping.categories[i];
    category.segments = splitIds.map(() => ({ count: 0 }));
    for (const split of dataCategory.segments!) {
      const id = segmentId(split);
      const j = splitIds.indexOf(id);
      if (j === -1) continue;
      category.segments[j] = { count: split.value };
      rawDataMapping.segments![i][j] = split;
    }
  });

  // Sort the split data into alphabetical order. The splits are a little trickier because we need to also sort all the data so it stays in sync.
  alphabetise(processedData.splits, ...processedData.categories.map((c) => c.segments!), ...rawDataMapping.segments);

  return { loading, error, rawDataMapping, data: processedData };
}

function segmentId(
  segment: NonNullable<RawAnalyticsQueryResult["reportsAnalysis"][number]["segments"]>[number],
): string {
  return (
    segment.groupId ?? segment.optionId ?? segment.status ?? segment.outcome?.id ?? segment.form?.id ?? segment.label
  );
}

function getLabel(data: AnalyticsQueryResult, split?: SplitSettings): string {
  if (data.groupId && split?.groups.basis === "CUSTOM") {
    const group = split.groups.groups.find((group) =>
      "id" in group ? group.id === data.groupId : group.ids.includes(data.groupId!),
    );
    if (group) return group.label;
  }
  return data.label;
}

/**
 * Orders an array of categories/segments in place by their names, and also sorts any number of additional arrays the same way — this is useful because the data structures we use here often rely on two arrays corresponding to one another, so when one changes order, the others should too.
 */
function alphabetise(main: Array<{ label: string }>, ...others: unknown[][]) {
  const order = main.map((_, i) => i);
  order.sort((a, b) => main[a].label.localeCompare(main[b].label));
  reorder(main, order);
  for (const other of others) reorder(other, order);
}

/** Sorts an array in-place given a specified order */
function reorder(array: unknown[], order: number[]) {
  const original = [...array];
  for (let i = 0; i < order.length; ++i) {
    array[i] = original[order[i]];
  }
}
