import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Crop } from "react-image-crop";

import { UploadImageMutation, UploadImageMutationVariables } from "../../../../../__generated__/graphql";
import AlertDangerIcon from "../../../../../shared/components/design-system/Alert/icons/AlertDangerIcon";
import Icon from "../../../../../shared/components/design-system/Icon";
import InputLabel from "../../../../../shared/components/design-system/InputLabel";
import LoadingSpinner from "../../../../../shared/components/design-system/LoadingSpinner";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import useGeneratedId from "../../../../../shared/hooks/useGeneratedId";
import { uploadFile } from "../../../../../shared/upload/uploadFile";
import { UPLOAD_IMAGE } from "../../../graphql/mutations";
import useOrg from "../../../hooks/useOrg";
import Button from "../Button";
import Cropper from "./Cropper";
import { Transform, createCroppedAndTransformedImage, imageFromFile } from "./imageUtils";
import "./styles.scss";

export interface ImageUploaderProps {
  onUpload: (url: string) => void;
  id?: string;
  fileUrl?: string | null;
  title: string;
  className?: string;
  width?: number;
  height?: number;
  noCrop?: boolean;
  required?: boolean;
}

const ImageUploader: FunctionComponent<ImageUploaderProps> = ({
  onUpload,
  id: providedId,
  fileUrl,
  title,
  className,
  width,
  height,
  noCrop,
  required,
}) => {
  const toast = useToast();
  const { currentSite } = useOrg();
  const id = useGeneratedId(providedId);
  const [editorOpen, setEditorOpen] = useState<boolean>(false);
  const [originalImage, setOriginalImage] = useState<HTMLImageElement | "ERROR" | undefined>();
  const [cropParams, setCropParams] = useState<Crop>({ unit: "%", width: 100, height: 100, x: 0, y: 0 });
  const [transformParams, setTransformParams] = useState<Transform>({ rotate: 0, flipH: false, flipV: false });
  const [isUploading, setImageUploading] = useState<boolean | "ERROR">(false);

  const [uploadImage] = useMutation<UploadImageMutation, UploadImageMutationVariables>(UPLOAD_IMAGE, {
    variables: { siteId: currentSite!.id },
  });

  const clearImage = useCallback(() => {
    if (originalImage && originalImage !== "ERROR") {
      URL.revokeObjectURL(originalImage.src);
      setOriginalImage(undefined);
    }
  }, [originalImage]);

  const selectFile = useCallback(
    async ([file]: File[]) => {
      if (noCrop) {
        setImageUploading(true);
        try {
          const uploadUrlsResult = await uploadImage();
          const {
            uploadImage: { publicUrl, uploadUrl },
          } = uploadUrlsResult.data!;
          await uploadFile(file, uploadUrl);
          setImageUploading(false);
          onUpload(publicUrl);
          mixpanel.track("Uploaded an image");
        } catch (e) {
          Sentry.captureException(e);
          toast.danger(`${title}: Image failed to upload`);
          setImageUploading("ERROR");
        }
      } else {
        setEditorOpen(true);
        try {
          setOriginalImage(await imageFromFile(file));
          setCropParams({ unit: "%", width: 100, height: 100, x: 0, y: 0 });
          setTransformParams({ rotate: 0, flipH: false, flipV: false });
        } catch (e) {
          setOriginalImage("ERROR");
        }
      }
    },
    [noCrop, onUpload, title, uploadImage, toast],
  );

  const cancelEdit = useCallback(() => {
    clearImage();
    setEditorOpen(false);
  }, [clearImage]);

  const saveImage = useCallback(async () => {
    setImageUploading(true);
    setEditorOpen(false);
    try {
      const uploadUrlsResult = await uploadImage();
      const {
        uploadImage: { publicUrl, uploadUrl },
      } = uploadUrlsResult.data!;
      await uploadFile(
        await createCroppedAndTransformedImage(
          originalImage as HTMLImageElement,
          height,
          width,
          cropParams,
          transformParams,
        ),
        uploadUrl,
      );
      setImageUploading(false);
      clearImage();
      onUpload(publicUrl);
      mixpanel.track("Uploaded an image");
    } catch (e) {
      Sentry.captureException(e);
      toast.danger(`${title}: Image failed to upload`);
      setImageUploading("ERROR");
    }
  }, [uploadImage, originalImage, height, width, cropParams, transformParams, clearImage, onUpload, title, toast]);

  const deleteImage = useCallback(() => {
    clearImage();
    setEditorOpen(false);
    onUpload(`https://via.placeholder.com/${width}x${height}`);
  }, [clearImage, height, onUpload, width]);

  const hasImage = !!fileUrl && !fileUrl.startsWith("https://via.placeholder.com") && !isUploading;

  const invalid = required && !hasImage ? "You must upload an image." : "";
  useEffect(() => {
    const el = document.getElementById(id) as HTMLInputElement;
    if (el) el.setCustomValidity(invalid);
  }, [invalid, id]);
  const [touched, setTouched] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const onInvalid = useCallback(() => {
    setTouched(true);
    if (ref.current && isFirstInvalidField(ref.current)) {
      ref.current.focus();
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: selectFile,
    disabled: isUploading === true,
  });

  return (
    <div className={`c-image-uploader ${className ?? ""} ${invalid && touched ? "c-image-uploader--invalid" : ""}`}>
      {editorOpen ? (
        <Cropper
          recommendedWidth={width}
          recommendedHeight={height}
          sourceImage={originalImage !== "ERROR" ? originalImage : undefined}
          isError={originalImage === "ERROR"}
          crop={cropParams}
          transform={transformParams}
          onCropChange={setCropParams}
          onTransformChange={setTransformParams}
          onSave={saveImage}
          onDelete={deleteImage}
          onCancel={cancelEdit}
        />
      ) : null}
      <InputLabel htmlFor={id}>
        {title}{" "}
        {width && height ? (
          <span className="c-image-uploader-label__size-hint">
            ({width} x {height} px)
          </span>
        ) : null}
      </InputLabel>
      <div
        className={`c-image-uploader__dropzone ${hasImage ? "c-image-uploader__dropzone--has-image" : ""}`}
        style={hasImage ? { backgroundImage: `url(${fileUrl})` } : {}}
        {...getRootProps()}
        ref={ref}
      >
        <input id={id} title={`Open ${title} uploader`} onInvalid={onInvalid} {...getInputProps()} />
        <div className="c-image-uploader__tint" />
        {isUploading === "ERROR" ? (
          <div className="c-image-uploader__status c-image-uploader__status--failed">
            <AlertDangerIcon />
            <p>Image failed to upload</p>
          </div>
        ) : isUploading ? (
          <div className="c-image-uploader__status c-image-uploader__status--uploading">
            <LoadingSpinner />
            <p>Uploading</p>
          </div>
        ) : !hasImage ? (
          <div className="c-image-uploader__action">
            <div>
              <Icon icon="image" size={32} />
              <br />
              <span className="c-image-uploader__action-highlight">Choose a file</span>
              <br />
              or drag it here
            </div>
          </div>
        ) : (
          <div className="c-image-uploader__action">
            <div>
              <Button type="button" variant="secondary">
                Replace
              </Button>
            </div>
          </div>
        )}
      </div>
      {invalid && touched ? <span className="c-image-uploader__validation">{invalid}</span> : null}
    </div>
  );
};

export default ImageUploader;

export function isFirstInvalidField(field: HTMLElement) {
  const form = findContainingForm(field);
  if (!form) return true;
  const first = findChildMatching(form, (el) => (el as HTMLInputElement).validity?.valid === false || el === field);
  return field === first;
}

function findChildMatching(root: HTMLElement, condition: (el: HTMLElement) => boolean): HTMLElement | null {
  if (condition(root)) return root;
  for (const child of root.children) {
    const match = findChildMatching(child as HTMLElement, condition);
    if (match) return match;
  }
  return null;
}

function findContainingForm(field: HTMLElement) {
  for (let el: HTMLElement | null = field; el; el = el.parentElement) {
    if (el.tagName === "FORM") {
      return el as HTMLFormElement;
    }
  }
}
