import { $isListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_NORMAL, KEY_TAB_COMMAND } from "lexical";
import { useEffect } from "react";

/** This plugin will overwrite default tabbing behaviour (i.e indentation on tab press). It will use lexical behaviour (for tab press) when in a list, because we want to indent on tab press for lists
 */
export default function DefaultTabbedPlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    editor.registerCommand<KeyboardEvent>(
      KEY_TAB_COMMAND,
      () => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return true;
        const nodes = selection.getNodes();
        if (nodes.length === 0) return true;
        try {
          return !nodes.every((node) => $isListNode(node.getTopLevelElementOrThrow()));
        } catch {
          return true;
        }
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [editor]);
  return null;
}
