import { LexicalCommand } from "lexical";
import React, { useState } from "react";

import { IconSlug } from "../../../../../../shared/components/design-system/Icon";
import IconButton from "../../Button/IconButton";
import { useIsRootEditor, useListenerRootEditorCommand, useRootEditorCommandWithPayload } from "../hooks";

export default function CommandButton<T>({
  command,
  canCommand,
  payload,
  label,
  icon,
  isActive = false,
}: {
  command: LexicalCommand<T>;
  canCommand?: LexicalCommand<boolean>;
  payload: T;
  isActive?: boolean;
  label: string;
  icon: IconSlug;
}) {
  const handleClick = useRootEditorCommandWithPayload(command, payload);

  // Commands like "undo" have a related command that the editor fires off whenever they become (un)available. We listen for that command and use it to toggle whether the button should be disabled.
  const [disabled, setDisabled] = useState(!!canCommand);
  useListenerRootEditorCommand(canCommand, (value) => setDisabled(!value));

  //Check if the the active editor is the root editor. This is to stop undos/redos in nested editors and stopping the undoing/redoing out of order that happens there- Ticket to fix this better in the future is CS-1866
  const isRootEditor = useIsRootEditor();

  return (
    <IconButton
      size="small"
      variant="tertiary"
      onClick={handleClick}
      isActive={isActive}
      disabled={disabled || !isRootEditor}
      label={label}
      icon={icon}
    />
  );
}
