import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useMemo, useRef, useState } from "react";

import Button from "../../../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../../../shared/components/design-system/Dialog";
import Icon from "../../../../../../../shared/components/design-system/Icon";
import LoadingSpinner from "../../../../../../../shared/components/design-system/LoadingSpinner";
import StringInput from "../../../../../../../shared/components/design-system/TextInput/StringInput";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../../shared/components/design-system/component-groups/section-header-footer";
import { isFirstInvalidField } from "../../../ImageUploader";
import { applyTransformations, imageFromFile } from "../../../ImageUploader/imageUtils";
import { createBlankCaption } from "../utils";
import { ImageNode } from "./ImageNode";
import { INSERT_IMAGE_COMMAND } from "./ImagePlugin";
import styles from "./styles.module.scss";
import useUploadInput from "./useUploadInput";

export default function InsertImageDialog({
  onCancel,
  alt,
  src,
  updateImageNode,
  siteId,
}: {
  onCancel: () => void;
  alt?: string;
  src?: string;
  siteId: string;
  updateImageNode?: (fn: (imageNode: ImageNode) => void) => void;
}) {
  const [editor] = useLexicalComposerContext();
  const [originalImage, setOriginalImage] = useState<HTMLImageElement | "ERROR" | undefined>();

  const ref = useRef<HTMLDivElement | null>(null);
  const [altText, setAltText] = useState<string>(alt ?? "");

  const onInvalid = useCallback(() => {
    if (ref.current && isFirstInvalidField(ref.current)) {
      ref.current.focus();
    }
  }, []);

  const { url, isUploading, getRootProps, getInputProps } = useUploadInput(
    siteId,
    "Clicked to upload an image",
    src,
    useCallback(async (file: File) => setOriginalImage(await imageFromFile(file)), []),
  );

  const saveImage = useCallback(() => {
    if (src && updateImageNode) {
      mixpanel.track("Image replaced", { altText });
      updateImageNode((imageNode) => {
        imageNode.updateImageNode(url, altText);
      });
    } else if (url) {
      mixpanel.track("Image inserted", { altText });
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        altText,
        src: url,
        type: "image",
        version: 1,
        indent: 0,
        caption: createBlankCaption(),
      });
    }
    onCancel();
  }, [altText, editor, onCancel, src, updateImageNode, url]);

  const previewImageUrl: string | undefined = useMemo(
    () =>
      originalImage !== "ERROR" && originalImage
        ? applyTransformations(originalImage, { rotate: 0, flipH: false, flipV: false }).toDataURL("image/jpeg", 1)
        : undefined,
    [originalImage],
  );

  return (
    <Dialog isOpen onClose={onCancel}>
      <SectionHeader title="Image editor" />

      {/* Appears when image is uploaded */}
      {(originalImage && originalImage !== "ERROR") || src ? (
        <div className="c-image-uploader-cropper">
          <div className={`${styles.container} ${styles.insertImageContainer}`}>
            <img src={previewImageUrl ?? src} alt={altText} />
            {isUploading ? (
              <div className="c-image-uploader__status c-image-uploader__status--uploading">
                <LoadingSpinner />
                <p>Uploading</p>
              </div>
            ) : (
              <div className={`${styles.dropzone}`} ref={ref} {...getRootProps()}>
                <input
                  type="file"
                  accept="image/*"
                  title={`Open image uploader`}
                  onInvalid={onInvalid}
                  {...getInputProps()}
                />

                <div className="c-image-uploader__action">
                  <div>
                    <Icon icon="image" size={32} />
                    <br />
                    <span className="c-image-uploader__action-highlight">Choose a file</span>
                    <br />
                    or drag it or drag it here
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {!originalImage && !src ? (
        <div
          className={`${styles.dropzone} ${isUploading === "ERROR" ? styles.uploadFailed : ""}`}
          ref={ref}
          {...getRootProps()}
        >
          <input
            type="file"
            accept="image/*"
            title={`Open image uploader`}
            onInvalid={onInvalid}
            {...getInputProps()}
          />
          {isUploading === "ERROR" ? (
            <div className="c-image-uploader__status c-image-uploader__status--failed">
              <div className="c-image-uploader__action">
                <div>
                  <Icon icon="image" size={32} />
                  <br />
                  <span className="c-image-uploader__action-highlight">Choose a file</span>
                  <br />
                  or drag it here
                </div>
              </div>
            </div>
          ) : isUploading === true ? (
            <div className="c-image-uploader__status c-image-uploader__status--uploading">
              <LoadingSpinner />
              <p>Uploading</p>
            </div>
          ) : (
            // Appears initially
            <div className="c-image-uploader__action">
              <div>
                <Icon icon="image" size={32} />
                <br />
                <span className="c-image-uploader__action-highlight">Choose a file</span>
                <br />
                or drag it here
              </div>
            </div>
          )}
        </div>
      ) : null}
      {isUploading === "ERROR" ? <span className="image-error-message">Image failed to upload</span> : null}

      <StringInput className={styles.altField} label="Alt text" value={altText} onChange={setAltText} />
      <p className="u-hint-text">Leave blank if image is decorative</p>
      <SectionFooter>
        <Button variant="tertiary" onClick={onCancel} label="Cancel" />
        <Button onClick={saveImage} disabled={!!isUploading} label={originalImage ? "Save" : "Insert"} />
      </SectionFooter>
    </Dialog>
  );
}
