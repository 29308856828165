import gql from "graphql-tag";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useEffect, useState } from "react";

import { SubscribeToNewsletterMutation } from "../../../../../__generated__/graphql";
import Alert from "../../../../../shared/components/design-system/Alert";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import styles from "../../../../../shared/components/design-system/Toaster/styles.module.scss";
import megaphone from "../../../assets/images/megaphone.svg";
import useMutationWithErrorReporting from "../../../hooks/useMutationWithErrorReporting";
import useSelf from "../../../hooks/useSelf";
import { useShownAnalyticsPrompt } from "../AnalyticsOptInControl/toast-extra";

const SUBSCRIBE_TO_NEWSLETTER_MUTATION = gql`
  mutation subscribeToNewsletter($subscribe: Boolean!) {
    answerNewsletterCta(subscribe: $subscribe) {
      id
      answeredNewsletterCta
    }
  }
`;

export default function useNewsletterCta() {
  const toast = useToast();
  const self = useSelf();

  const shownAnalyticsPrompt = useShownAnalyticsPrompt();

  const [subscribe] = useMutationWithErrorReporting<SubscribeToNewsletterMutation>(SUBSCRIBE_TO_NEWSLETTER_MUTATION, {
    defaultMessage: "An error occurred while saving your preferences",
  });

  useEffect(() => {
    if (!self.answeredNewsletterCta && !shownAnalyticsPrompt) {
      const id = toast.showExtra(NewsletterOptInControl, {
        onAccept: async () => {
          await subscribe({ variables: { subscribe: true } });
          toast.success("Thank you for subscribing");
          toast.removeExtra(id);
        },
        onDecline: async () => {
          await subscribe({ variables: { subscribe: false } });
          toast.removeExtra(id);
        },
      });
    }
  }, [self.answeredNewsletterCta, shownAnalyticsPrompt, subscribe, toast]);
}

function NewsletterOptInControl({ onAccept, onDecline }: { onAccept: () => void; onDecline: () => void }) {
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const subscribe = useCallback(() => {
    mixpanel.track("Subscribed to newsletter");
    onAccept();
    setSubscribeLoading(true);
  }, [onAccept]);

  const [dismissLoading, setDismissLoading] = useState(false);
  const dismiss = useCallback(() => {
    mixpanel.track("Declined to subscribe to newsletter");
    onDecline();
    setDismissLoading(true);
  }, [onDecline]);

  return (
    <Alert
      className={styles.toastAlert}
      onClickClose={dismiss}
      icon={<img src={megaphone} alt="" width={96} height={88} />}
      actions={[
        {
          label: "Dismiss",
          disabled: subscribeLoading,
          variant: "tertiary",
          loading: dismissLoading,
          onClick: dismiss,
        },
        {
          label: "Sign up",
          disabled: dismissLoading,
          variant: "primary",
          loading: subscribeLoading,
          onClick: subscribe,
        },
      ]}
    >
      Sign up to our monthly newsletter to keep up to date with the latest product updates and new features
    </Alert>
  );
}
