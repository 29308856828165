import useOrg from "./useOrg";
import { OrgDataLoggedInUser } from "./useOrg/types";

export type Self = OrgDataLoggedInUser & { isSuperAdmin: boolean };

/** @deprecated No longer does anything really, but wraps the new useOrg hook so no code changes are needed */
export default function useSelf(): Self {
  const { loggedInUser, isSuperAdmin } = useOrg();
  return { ...loggedInUser, isSuperAdmin };
}
