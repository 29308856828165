import { gql, useMutation } from "@apollo/client";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import * as Sentry from "@sentry/browser";
import { $getNodeByKey } from "lexical";
import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react";

import {
  UploadImageFromUrlMutation,
  UploadImageFromUrlMutationVariables,
} from "../../../../../../../__generated__/graphql";
import { useToast } from "../../../../../../../shared/components/design-system/Toaster/context";
import useOrg from "../../../../../hooks/useOrg";
import { $isImageNode, ImageNode, isInAttachmentBucket } from "./ImageNode";

type UploadHandler = (url: string, onError: (error: Error) => void) => { uploadedUrl: string | null; loading: boolean };

const context = createContext<UploadHandler>(() => {
  throw new Error("useUpload called outside a provider");
});

/** A root component which handles state for image uploads. Keeping it in a root component means that state can persist between re-renders of the actual contents. */
export function UploadProvider({ children }: PropsWithChildren<unknown>) {
  const { currentSite } = useOrg();

  const [completedUploads, setCompletedUploads] = useState<
    Array<{ originalUrl: string; publicUrl: string; loading: boolean }>
  >([]);

  const [ongoingUploads, setOngoingUploads] = useState<Array<string>>([]);

  const [uploadImage] = useMutation<UploadImageFromUrlMutation, UploadImageFromUrlMutationVariables>(
    gql`
      mutation uploadImageFromUrl($siteId: ID!, $url: String!) {
        uploadImage(siteId: $siteId, fileUrl: $url) {
          publicUrl
        }
      }
    `,
  );

  const handleUpload = useCallback<UploadHandler>(
    (url, onError) => {
      if (!currentSite) throw new Error("Files cannot be uploaded outside a site context");

      // First things first, if this URL is one we've seen before then return what we know about it:
      const completed = completedUploads.find((upload) => upload.publicUrl === url || upload.originalUrl === url);
      if (completed) return { uploadedUrl: completed.publicUrl, loading: completed.loading };
      if (ongoingUploads.includes(url)) return { uploadedUrl: null, loading: true };

      // If this looks like the URL of something in our uploads bucket then just return it, we don't need to do anything else with it:
      if (isInAttachmentBucket(url)) return { uploadedUrl: url, loading: false };

      // If we haven't bailed already, it means we have a new image to upload.
      uploadImage({ variables: { url, siteId: currentSite.id } })
        .then(({ data }) => {
          // The upload is complete, but for now, leave the 'loading' tag true — the public URL might not work right away and we don't want to re-render until we're confident it will:
          setCompletedUploads((uploads) => [
            ...uploads,
            { originalUrl: url, publicUrl: data!.uploadImage.publicUrl, loading: true },
          ]);
          // Set a job to clear the 'loading' flag after ten seconds. This will trigger a re-render, which should contain the actual image:
          setTimeout(
            () =>
              setCompletedUploads((uploads) => [
                ...uploads.filter((upload) => upload.originalUrl !== url),
                { originalUrl: url, publicUrl: data!.uploadImage.publicUrl, loading: false },
              ]),
            10000,
          );
        })
        .catch(onError)
        .finally(() => setOngoingUploads((uploads) => uploads.filter((upload) => upload !== url)));
      setOngoingUploads((uploads) => [...uploads, url]);
      return { uploadedUrl: null, loading: true };
    },
    [completedUploads, ongoingUploads, uploadImage, currentSite],
  );

  return <context.Provider value={handleUpload}>{children}</context.Provider>;
}

/** A hook for uploading images. If you give it the URL of an image, it will work out whether it needs to be uploaded, handle the result of that, and return you simply a boolean for whether or not you should be displaying a loading spinner. Only works inside a site context. */
export default function useUpload(nodeKey: string, url: string) {
  const toast = useToast();
  const [editor] = useLexicalComposerContext();
  const { currentSite } = useOrg();

  if (!currentSite) throw new Error("Files cannot be uploaded outside a site context");

  const updateLexicalNode = useCallback(
    (callback: (node: ImageNode) => void) => {
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if (!$isImageNode(node)) throw new Error("Unexpected node type encountered");
        callback(node);
      });
    },
    [editor, nodeKey],
  );

  const uploadIfNeeded = useContext(context);
  const { uploadedUrl, loading } = uploadIfNeeded(url, (error: Error) => {
    Sentry.captureException(error);
    console.error("Failed to upload image", error);
    toast.error(error, { title: "Failed to upload image" });
    updateLexicalNode((node) => node.remove());
  });

  useEffect(() => {
    if (!uploadedUrl || uploadedUrl === url) return;
    updateLexicalNode((node) => node.updateImageNode(uploadedUrl, node.getAltText(), node.getKey()));
  }, [uploadedUrl, url, updateLexicalNode]);

  return { loading };
}
