import React from "react";

import StringInput from "../../../../../shared/components/design-system/TextInput/StringInput";
import "./styles.scss";

type Variant = "top-bar" | "wide";

export interface SearchInputProps {
  label: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
  variant?: Variant;
}

export default function SearchInput({
  label,
  autoFocus,
  onChange,
  placeholder = "Search",
  value,
  variant = "wide",
}: SearchInputProps) {
  return (
    <StringInput
      label={label}
      labelVisuallyHidden
      autoFocus={autoFocus}
      className={`c-search-input c-search-input--${variant}`}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      icon="search"
      button={
        value
          ? {
              label: "Clear search term",
              icon: "xmark",
              onClick: () => onChange(""),
            }
          : undefined
      }
    />
  );
}
