import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { FormEvent, useCallback, useState } from "react";

import Button from "../../../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../../../shared/components/design-system/Dialog";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../../shared/components/design-system/component-groups/section-header-footer";
import HtmlInput from "./HtmlInput";
import { INSERT_HTML_SNIPPET_COMMAND } from "./HtmlSnippetPlugin";

export default function InsertSnippetModal({ isOpen, close }: { isOpen: boolean; close: () => void }) {
  const [src, setSrc] = useState("");
  const [editor] = useLexicalComposerContext();
  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      editor.dispatchCommand(INSERT_HTML_SNIPPET_COMMAND, { src, version: 1, type: "html-snippet", indent: 0 });
      close();
    },
    [editor, close, src],
  );

  return (
    <Dialog isOpen={isOpen} onClose={close}>
      <form onSubmit={onSubmit}>
        <SectionHeader title="Edit HTML snippet" />
        <div>
          <HtmlInput value={src} label="HTML snippet" onChange={setSrc} />
        </div>
        <SectionFooter>
          <Button variant="tertiary" onClick={close} label="Cancel" />
          <Button submit label="OK" />
        </SectionFooter>
      </form>
    </Dialog>
  );
}
