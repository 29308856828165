import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useRef, useState } from "react";

import Button from "../../../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../../../shared/components/design-system/Dialog";
import Icon from "../../../../../../../shared/components/design-system/Icon";
import LoadingSpinner from "../../../../../../../shared/components/design-system/LoadingSpinner";
import StringInput from "../../../../../../../shared/components/design-system/TextInput/StringInput";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../../../shared/components/design-system/component-groups/section-header-footer";
import { isFirstInvalidField } from "../../../ImageUploader";
import styles from "../ImageBlock/styles.module.scss";
import useUploadInput from "../ImageBlock/useUploadInput";
import { FileUploadNode } from "./FileUploadNode";
import { INSERT_FILE_COMMAND } from "./FileUploadPlugin";

export default function InsertFileUploadDialog({
  onCancel,
  defaultFileName,
  defaultFileSize,
  defaultMimeType,
  src,
  updateFileUploadNode,
  siteId,
}: {
  onCancel: () => void;
  defaultFileName?: string;
  defaultFileSize?: number;
  defaultMimeType?: string;
  src?: string;
  siteId: string;
  updateFileUploadNode?: (fn: (fileUploadNode: FileUploadNode) => void) => void;
}) {
  const [editor] = useLexicalComposerContext();

  const ref = useRef<HTMLDivElement | null>(null);
  const [fileName, setFileName] = useState(defaultFileName ?? "");
  const [fileSize, setFileSize] = useState(defaultFileSize ?? 0);
  const [mimeType, setMimeType] = useState(defaultMimeType ?? "");

  const onInvalid = useCallback(() => {
    if (ref.current && isFirstInvalidField(ref.current)) {
      ref.current.focus();
    }
  }, []);

  const { url, isUploading, getRootProps, getInputProps } = useUploadInput(
    siteId,
    "Clicked to upload a file",
    src,
    useCallback(async (file: File) => {
      setFileName(file.name);
      setFileSize(file.size);
      setMimeType(file.type);
    }, []),
  );

  const saveFile = useCallback(() => {
    console.log("Saving file");
    if (src && updateFileUploadNode) {
      mixpanel.track("File upload replaced", { fileName });
      updateFileUploadNode((fileUploadNode) => {
        fileUploadNode.updateFileUploadNode(url, fileName, fileSize, mimeType);
      });
    } else if (url) {
      mixpanel.track("File upload inserted", { fileName });
      editor.dispatchCommand(INSERT_FILE_COMMAND, {
        src: url,
        type: "file-upload",
        fileName,
        fileSize,
        mimeType,
        version: 1,
        indent: 0,
      });
    } else {
      throw new Error("File upload URL not found");
    }
    onCancel();
  }, [editor, fileName, fileSize, mimeType, onCancel, src, updateFileUploadNode, url]);

  if (!isUploading && !url) {
    return (
      <Dialog isOpen onClose={onCancel}>
        <SectionHeader title="Upload file" />
        <div className={styles.dropzone} ref={ref} {...getRootProps()}>
          <input type="file" accept="*/*" title="Open file uploader" onInvalid={onInvalid} {...getInputProps()} />
          {/* TODO: Do we need our own classes here? */}
          <div className="c-image-uploader__action">
            <div>
              <Icon icon="file" size={32} />
              <br />
              <span className="c-image-uploader__action-highlight">Choose a file</span>
              <br />
              or drag it here
            </div>
          </div>
        </div>
        <SectionFooter>
          <Button variant="tertiary" onClick={onCancel} label="Cancel" />
          <Button onClick={onCancel} label="Insert" />
        </SectionFooter>
      </Dialog>
    );
  }

  if (isUploading === "ERROR") {
    return (
      <Dialog isOpen onClose={onCancel}>
        <SectionHeader title="Upload file" />
        <span className="image-error-message">File failed to upload</span>
        <SectionFooter>
          <Button variant="tertiary" onClick={onCancel} label="Cancel" />
          <Button onClick={onCancel} disabled label="Insert" />
        </SectionFooter>
      </Dialog>
    );
  }

  if (isUploading) {
    return (
      <Dialog isOpen onClose={onCancel}>
        <SectionHeader title="Upload file" />
        <LoadingSpinner />
        <SectionFooter>
          <Button variant="tertiary" onClick={onCancel} label="Cancel" />
          <Button onClick={onCancel} disabled label="Insert" />
        </SectionFooter>
      </Dialog>
    );
  }
  return (
    <Dialog isOpen onClose={onCancel}>
      <SectionHeader title={defaultFileName ? "Edit uploaded file" : "Upload file"} />
      <form onSubmit={saveFile} className="simple-form-editor">
        <StringInput className={styles.altField} label="Filename" value={fileName} onChange={setFileName} required />
        <SectionFooter>
          <Button variant="tertiary" onClick={onCancel} label="Cancel" />
          <Button submit label={defaultFileName ? "Save" : "Insert"} />
        </SectionFooter>
      </form>
    </Dialog>
  );
}
