import { useMutation } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CreateDocumentMutation, CreateDocumentMutationVariables } from "../../../../../__generated__/graphql";
import Button from "../../../../../shared/components/design-system/Button";
import { InternalLink } from "../../../../../shared/components/design-system/Button/Link";
import Dialog from "../../../../../shared/components/design-system/Dialog";
import StringInput from "../../../../../shared/components/design-system/TextInput/StringInput";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../shared/components/design-system/component-groups/section-header-footer";
import { useOpenableWithTracking } from "../../../hooks/useBoolean";
import useOrgData from "../../../hooks/useOrgData";
import useSiteData from "../useSiteData";
import { usePersistence } from "./persistence";
import { CREATE_DOCUMENT } from "./queries";

export default function DuplicateReportButton() {
  const { savedDocument: doc } = usePersistence();
  const { isCrossOrg, organisation } = useOrgData();
  const site = useSiteData();
  const toast = useToast();
  const navigate = useNavigate();

  const [newTitle, setNewTitle] = useState("");
  const { isOpen, open, close } = useOpenableWithTracking("Duplicate document", {
    documentType: doc?.documentType,
  });

  const onClick = useCallback(() => {
    // TODO: Translate this string:
    setNewTitle(`Copy of ${doc!.name}`);
    open();
  }, [doc, open, setNewTitle]);

  const [duplicate, { loading }] = useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(
    CREATE_DOCUMENT,
    {
      variables: {
        siteId: site.id,
        name: newTitle,
        // @ts-ignore — this will exist when the hook is run
        documentType: doc?.documentType,
        // @ts-ignore — this will exist when the hook is run
        body: doc?.currentDraft.body,
        makePreviewable: false,
      },
    },
  );

  const onSubmit = useCallback(async () => {
    try {
      const copy = await duplicate();
      const duplicateId = copy.data!.createDocument.id;
      mixpanel.track("Duplicated document", { documentType: doc!.documentType, duplicateId });
      const baseUrl = isCrossOrg ? `/organisations/${organisation.id}/sites/${site.id}` : `/sites/${site.id}`;
      navigate(baseUrl);
      toast.success(
        <>
          Document was successfully duplicated:{" "}
          <InternalLink to={`${baseUrl}/insights/${duplicateId}`} label={copy.data!.createDocument.name} />
        </>,
      );
    } catch (e) {
      // @ts-ignore - we don't know what type `e` will be but this should work with anything:
      toast.error(e?.message ?? "", { title: "Unable to duplicate report" });
    }
  }, [doc, duplicate, isCrossOrg, navigate, organisation.id, site.id, toast]);

  if (!doc) return null;

  return (
    <>
      <Button variant="action" onClick={onClick} icon="clone" label="Duplicate" />
      <Dialog isOpen={isOpen} onClose={close}>
        <SectionHeader title="Duplicate Document" />
        <form onSubmit={onSubmit}>
          <p>This will add a new document. Please enter a new title below.</p>
          <StringInput
            className="ds-mt-4"
            label="Document title"
            value={newTitle}
            onChange={setNewTitle}
            disabled={loading}
            required
          />
          <SectionFooter>
            <Button variant="tertiary" onClick={close} disabled={loading} label="Cancel" />
            <Button variant="primary" submit loading={loading} label="Duplicate" />
          </SectionFooter>
        </form>
      </Dialog>
    </>
  );
}
